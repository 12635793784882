import apiCall from "utils/api"
import { setCurrentUser } from "reducers/auth"

export const requestPayment = () => async dispatch => {
  return apiCall("post", `users/request_referral_payments`)
    .then(response => {
      dispatch(setCurrentUser(response.data))
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const sendReferrals = async referrals => {
  return apiCall("post", `users/refer_users_by_email`, referrals)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
