import Modal from "react-bootstrap/Modal"
import ReferralForm from "./Form"

import styles from "./Modal.module.scss"

const ReferralModal = ({ show, handleClose, referralLink }) => {
  return (
    <Modal show={show} onHide={handleClose} className={styles.modal}>
      <Modal.Body>
        <ReferralForm
          referralLink={referralLink}
          handleClose={handleClose}
          modal
        />
      </Modal.Body>
    </Modal>
  )
}

export default ReferralModal
