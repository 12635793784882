import { useEffect, useMemo } from "react"
import { usePathname } from "next/navigation"
import { toggleScroll } from "utils/common"

import { useDispatch, useSelector } from "react-redux"
import { trackAction } from "utils/analytics"
import { openReRateModal } from "reducers/auth"

const useAccessCheck = () => {
  const pathname = usePathname()
  const dispatch = useDispatch()

  const { currentUser, authenticated, loading } = useSelector(
    state => state.auth
  )

  const { days_until_next_rating, status } = currentUser

  const exclusivePaths = useMemo(() => [/^\/companies\//, /^\/salaries/], [])

  const pathMatchesRegex = (path, regex) => {
    return regex.test(path)
  }

  useEffect(() => {
    if (days_until_next_rating === 0 && status !== "new_to_sales") {
      for (let path of exclusivePaths) {
        if (pathMatchesRegex(pathname, path)) {
          trackAction("Access Expired")
          dispatch(openReRateModal())
        }
      }
    }
    if (!location.pathname.match(/^\/companies\/(?!compare\b).*$/)) {
      toggleScroll(true)
    } else {
      if (localStorage.lockout && !authenticated) {
        toggleScroll(false)
      }
    }
  }, [
    days_until_next_rating,
    dispatch,
    status,
    authenticated,
    loading,
    pathname,
    exclusivePaths,
  ])
}

export default useAccessCheck
