import { useRef, useEffect } from "react"
/**
 * groupBy
 * @param {Array} array list of elements
 * @param {String} label label to group
 * @returns
 */
export const groupBy = (array, label) => {
  const group = array?.reduce(
    (r, v, i, a, k = v[label]) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  )
  return group
}

/**
 * debounce
 * @param {Function} func function
 * @param {Number} wait time to wait
 * @param {Boolean} [immediate]
 * @returns
 */
export const debounce = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this,
      args = arguments
    clearTimeout(timeout)
    if (immediate && !timeout) func.apply(context, args)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
  }
}

/**
 * snakeCase
 * @param {String} string
 * @returns
 */
export const snakeCase = (string = "") => {
  const strArr = string.split(" ")
  const snakeArr = strArr.reduce((acc, val) => {
    return acc.concat(val.toLowerCase())
  }, [])
  return snakeArr.join("_")
}

/**
 * range
 * @param {Number} number
 * @returns
 */
export const range = number => [...Array(number).keys()]

export const usePreviousValue = value => {
  const prevValueRef = useRef()

  useEffect(() => {
    prevValueRef.current = value
  }, [value])

  return prevValueRef.current
}

/**
 * roundNumber
 * @param {Number} number
 * @param {Number} place
 * @returns
 */
export const roundNumber = (number, place = 0) => {
  const denominator = number >= 1000000 ? 1000000 : 1000
  const roundedNumber = ((number / denominator) * 10) / 10

  const roundsToZero =
    place === 1 && roundedNumber.toFixed(place).toString()[2] === "0"

  return Number.isInteger(roundedNumber) || roundsToZero
    ? Math.round(roundedNumber)
    : roundedNumber.toFixed(place)
}

/**
 * sortBy
 * @param {String} key
 * @returns
 */
export const sortBy = key => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0)
}

/**
 * sortBy
 * @param {Boolean} scroll
 * * @param {Boolean} isMobile
 * @returns
 */
export const toggleScroll = (scroll, isMobile) => {
  if (scroll) {
    document.body.classList.remove("body-locked")
    if (isMobile) {
      document.getElementById("site-nav").classList.add("nav-locked")
    }
  } else {
    document.body.classList.add("body-locked")
    if (isMobile) {
      document.getElementById("site-nav").classList.add("nav-locked")
    }
  }
}

export const rolesEnumerated = {
  "sales-development-representative": "SDR/BDR",
  "sdr-bdr": "SDR/BDR",
  "account-executive": "Account Executive",
  "account-manager": "Account Manager",
  "customer-success-manager": "Customer Success Manager",
  "sales-engineer": "Sales Engineer",
  "sales-enablement": "Sales Enablement",
  "sales-manager": "Sales Manager",
  "other-sales": "Other Sales",
  "sales-engineer": "Sales Engineer",
  "partner-channel-sales": "Partner or Channel Sales",
  "enterprise-account-executive": "Enterprise Account Executive",
  "smb-account-executive": "SMB Account Executive",
  "strategic-account-executive": "Strategic Account Executive",
  "mid-market-account-executive": "Mid Market Account Executive",
  "sales-development-manager": "Sales Development Manager",
  "sled-account-executive": "SLED Account Executive",
  "sales-leadership": "Sales Leadership",
  "customer-success": "Customer Success",
  "clinical-specialist": "Clinical Specialist",
}
