export const SORT_KEYS = {
  companies: {
    name: "companies.name",
    base: "company_salaries.median_base_compensation_cents",
    ote: "company_salaries.median_ote_cents",
    top: "company_salaries.average_highest_potential_earnings_cents",
  },
  cities: {
    name: "metro_locations.name",
    base: "metro_location_salaries.median_base_compensation_cents",
    ote: "metro_location_salaries.median_ote_cents",
    top: "metro_location_salaries.average_highest_potential_earnings_cents",
  },
}

/* eslint-disable sonarjs/no-duplicate-string */

export const salariesByCity = [
  {
    id: 5,
    location: "Atlanta, GA",
    role_parameterized: "account-executive",
    location_parameterized: "atlanta-ga",
  },
  {
    id: 12,
    location: "Austin, TX",
    role_parameterized: "account-executive",
    location_parameterized: "austin-round-rock-tx",
  },
  {
    id: 1,
    location: "Boston, MA",
    role_parameterized: "account-executive",
    location_parameterized: "boston-providence-ma-ri-nh-ct",
  },
  {
    id: 8,
    location: "Chicago, IL",
    role_parameterized: "account-executive",
    location_parameterized: "chicago-il",
  },
  {
    id: 10,
    location: "Los Angeles, CA",
    role_parameterized: "account-executive",
    location_parameterized: "los-angeles-anaheim-ca",
  },
  {
    id: 17,
    location: "San Francisco, CA",
    role_parameterized: "account-executive",
    location_parameterized: "san-francisco-oakland-ca",
  },
  {
    id: 13,
    location: "San Jose, CA",
    role_parameterized: "account-executive",
    location_parameterized: "san-jose-silicon-valley-ca",
  },
  {
    id: 3,
    location: "New York, NY",
    role_parameterized: "account-executive",
    location_parameterized: "greater-new-york-ny-nj-ct",
  },
  {
    id: 15,
    location: "Seattle, WA",
    role_parameterized: "account-executive",
    location_parameterized: "seattle-tacoma-wa",
  },
  {
    id: 6,
    location: "Atlanta, GA",
    role_parameterized: "sales-development-representative",
    location_parameterized: "atlanta-ga",
  },
  {
    id: 11,
    location: "Austin, TX",
    role_parameterized: "sales-development-representative",
    location_parameterized: "austin-round-rock-tx",
  },
  {
    id: 2,
    location: "Boston, MA",
    role_parameterized: "sales-development-representative",
    location_parameterized: "boston-providence-ma-ri-nh-ct",
  },
  {
    id: 7,
    location: "Chicago, IL",
    role_parameterized: "sales-development-representative",
    location_parameterized: "chicago-il",
  },
  {
    id: 9,
    location: "Los Angeles, CA",
    role_parameterized: "sales-development-representative",
    location_parameterized: "los-angeles-anaheim-ca",
  },
  {
    id: 18,
    location: "San Francisco, CA",
    role_parameterized: "sales-development-representative",
    location_parameterized: "san-francisco-oakland-ca",
  },
  {
    id: 14,
    location: "San Jose, CA",
    role_parameterized: "sales-development-representative",
    location_parameterized: "san-jose-silicon-valley-ca",
  },
  {
    id: 4,
    location: "New York, NY",
    role_parameterized: "sales-development-representative",
    location_parameterized: "greater-new-york-ny-nj-ct",
  },
  {
    id: 16,
    location: "Seattle, WA",
    role_parameterized: "sales-development-representative",
    location_parameterized: "seattle-tacoma-wa",
  },
]

export const salariesByCountry = [
  {
    location: "Australia",
    role_parameterized: "account-executive",
    location_parameterized: "AU",
  },
  {
    location: "Canada",
    role_parameterized: "account-executive",
    location_parameterized: "CA",
  },
  {
    location: "Germany",
    role_parameterized: "account-executive",
    location_parameterized: "DE",
  },
  {
    location: "India",
    role_parameterized: "account-executive",
    location_parameterized: "IN",
  },
  {
    location: "Ireland",
    role_parameterized: "account-executive",
    location_parameterized: "IE",
  },
  {
    location: "The Netherlands",
    role_parameterized: "account-executive",
    location_parameterized: "NL",
  },
  {
    location: "Singapore",
    role_parameterized: "account-executive",
    location_parameterized: "SG",
  },
  {
    location: "Spain",
    role_parameterized: "account-executive",
    location_parameterized: "ES",
  },
  {
    location: "the UK",
    role_parameterized: "account-executive",
    location_parameterized: "GB",
  },
  {
    location: "Australia",
    role_parameterized: "sales-development-representative",
    location_parameterized: "AU",
  },
  {
    location: "Canada",
    role_parameterized: "sales-development-representative",
    location_parameterized: "CA",
  },
  {
    location: "Germany",
    role_parameterized: "sales-development-representative",
    location_parameterized: "DE",
  },
  {
    location: "India",
    role_parameterized: "sales-development-representative",
    location_parameterized: "IN",
  },
  {
    location: "Ireland",
    role_parameterized: "sales-development-representative",
    location_parameterized: "IE",
  },
  {
    location: "The Netherlands",
    role_parameterized: "sales-development-representative",
    location_parameterized: "NL",
  },
  {
    location: "Singapore",
    role_parameterized: "sales-development-representative",
    location_parameterized: "SG",
  },
  {
    location: "Spain",
    role_parameterized: "sales-development-representative",
    location_parameterized: "ES",
  },
  {
    location: "the UK",
    role_parameterized: "sales-development-representative",
    location_parameterized: "GB",
  },
  {
    location: "Belgium",
    role_parameterized: "sales-development-representative",
    location_parameterized: "BE",
  },
  {
    location: "Italy",
    role_parameterized: "sales-development-representative",
    location_parameterized: "IT",
  },
  {
    location: "France",
    role_parameterized: "sales-development-representative",
    location_parameterized: "FR",
  },
  {
    location: "Israel",
    role_parameterized: "sales-development-representative",
    location_parameterized: "IL",
  },
  {
    location: "Sweden",
    role_parameterized: "sales-development-representative",
    location_parameterized: "SE",
  },
  {
    location: "Brazil",
    role_parameterized: "sales-development-representative",
    location_parameterized: "BR",
  },
]

export const medDeviceSalaries = [
  {
    role: "Medical Device Sales Representative",
    role_parameterized: "medical-device-sales-representative",
  },
]
