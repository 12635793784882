import { useState } from "react"
import cn from "classnames"

import Form from "react-bootstrap/Form"

import Eye from "icons/eye.svg"
import Lock from "icons/lock.svg"

import styles from "./PasswordField.module.scss"

const PasswordField = ({ retype, value, handleChange, showIcon = true }) => {
  const [seePassword, setSeePassword] = useState(false)

  const toggleSeePassword = () => {
    setSeePassword(!seePassword)
  }

  return (
    <div className={cn(styles.inputWrapper, "mb-1")}>
      {showIcon && <Lock height="20" width="20" className={styles.iconLeft} />}
      <Form.Control
        className={cn(styles.input, {
          [styles.input__icon]: showIcon,
          [styles.input__noIcon]: !!!showIcon,
        })}
        type={seePassword ? "text" : "password"}
        placeholder={retype ? "Retype Password" : "Password"}
        value={value}
        name={retype ? "retypePassword" : "password"}
        id="password-field"
        onChange={handleChange}
      />
      <Eye
        height="20"
        width="20"
        className={cn(styles.iconRight, {
          [styles.activeEye]: seePassword,
        })}
        onClick={toggleSeePassword}
      />
    </div>
  )
}

export default PasswordField
