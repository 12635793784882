import Link from "next/link"
import CookieConsent from "react-cookie-consent"
import Cookie from "icons/cookie.svg"

import styles from "./CookieConsent.module.scss"

const CookieConsentModal = () => {
  return (
    <CookieConsent
      buttonText="Acknowledge"
      cookieName="repvue_consent"
      contentClasses="cookie-consent-content"
      containerClasses={styles.wrapper}
      expires={150}
      disableStyles={true}
    >
      <strong>
        <Cookie height="26" width="26" />
        We Use Cookies
      </strong>
      <p>
        RepVue uses cookies to provide necessary website functionality, improve
        your experience, and analyze our traffic. By using our website, you
        agree to our <Link href="/privacy">Privacy Policy</Link>.
      </p>
    </CookieConsent>
  )
}

export default CookieConsentModal
