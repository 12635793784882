import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "reducers"
import { createWrapper } from "next-redux-wrapper"

const makeStore = () =>
  configureStore({
    reducer: rootReducer,
  })

const store = configureStore({
  reducer: rootReducer,
})

const wrapper = createWrapper(makeStore)

export { store, wrapper }
