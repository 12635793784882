/**
 * validateEmail
 * This function validates if a email is valid
 * @param {String} email Email to validate
 * @returns
 */

export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

/**
 * validateApplication
 * @param {Array} fields
 * @returns
 */

export const validateApplication = fields => {
  let flag = true

  fields.forEach(field => {
    const { question_sets, questions } = field
    if (question_sets.length) {
      question_sets.forEach(question_set => {
        question_set.questions.forEach(question => {
          if (question.required && !question.answer) {
            flag = false
          }
        })
      })
    }

    if (questions.length) {
      questions.forEach(question => {
        if (question.required && !question.answer) {
          flag = false
        }
      })
    }
  })

  return !flag
}

/**
 * validateApplication
 * This function validates if a url is valid
 * @param {String} url Url to validate
 * @returns
 */

export const validateUrl = url => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  )
  return !!urlPattern.test(url)
}

export const isMobileSafari = () => {
  return (
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/)
  )
}

export const isNotFound = (responses = []) => {
  let notFound = false
  const errorResponses = [404, 500, 422]
  responses.forEach(response => {
    if (errorResponses.includes(response)) {
      notFound = true
    }
  })

  return notFound
}
