import Logo from "components/logo"
import Socials from "components/shared/socials"
import Image from "next/image"

import maintenanceImg from "./maintenance.png"

import styles from "./Maintenance.module.scss"

const Maintenance = () => (
  <main>
    <div className={styles.maintenance}>
      <article className={styles.logo}>
        <Logo allowNavigation={false} />
      </article>
      <h1>This site is currently down for maintenance</h1>
      <p>
        We are currently working to deliver a better user experience, please
        come back later.
      </p>
      <article className={styles.icon}>
        <Image
          src={maintenanceImg}
          width={545}
          height={189}
          alt="Site Maintenance"
        />
      </article>
      <section className={styles.socials}>
        <Socials />
      </section>
      <span className={styles.copyright}>
        &#169; {new Date().getFullYear()} All Rights Reserved
      </span>
    </div>
  </main>
)

export default Maintenance
