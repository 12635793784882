import apiCall from "utils/api"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { identifyUser } from "utils/analytics"
import { setCurrentUser, signOutUser, setLoading } from "reducers/auth"
import { resetUser } from "utils/analytics"

export const signIn = params => async dispatch => {
  const options = { data: params }
  return apiCall("post", `users/sign_in`, options)
    .then(response => {
      const user = response.data
      identifyUser(user, {
        days_until_next_rating: user?.days_until_next_rating,
        $created: user?.created_at,
        $email: user?.email,
      })
      dispatch(setCurrentUser(user))
      return response
    })
    .catch(error => {
      console.error("sign in", error)
      throw error
    })
}

export const signOut = () => async dispatch => {
  resetUser()
  return apiCall("delete", `users/sign_out`)
    .then(response => {
      dispatch(signOutUser())
      return response
    })
    .catch(error => {
      dispatch(signOutUser())
      console.error("sign out", error)
      throw error
    })
}

export const resetPassword = params => async () => {
  const options = { data: params }
  return apiCall("post", `users/password`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error("reset password", error)
      throw error
    })
}

export const completeRegistration = params => async () => {
  const options = { data: params }
  return apiCall("put", `users/password?onboarding=true`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error("complete registration", error)
      throw error
    })
}

export const changePassword = params => async () => {
  const options = { data: params }
  return apiCall("put", `users/password`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error("change password", error)
      throw error
    })
}

export const getCurrentUser = createAsyncThunk(
  "users/getCurrentUser",
  async onboarding => {
    const queryString = onboarding ? `?onboarding=${onboarding}` : ""
    return apiCall("get", `users/me${queryString}`)
      .then(response => {
        const user = response.data
        return user
      })
      .catch(error => {
        dispatch(signOutUser())
        console.error("get current user", error)
        throw error
      })
  }
)

export const confirmEmail = (token, params) => async () => {
  const options = { data: params }
  return apiCall("put", `users/${token}/confirm_email`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error("change password", error)
      throw error
    })
}

export const completeAuthCheck = () => async dispatch => {
  dispatch(setLoading())
}

export const acceptInvitation = params => async dispatch => {
  const options = { data: params }
  return apiCall("put", `users/invitation`, options)
    .then(response => {
      dispatch(getCurrentUser())
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}
