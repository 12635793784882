const keyName = "auth"

/**
 *
 * @param {String} auth authHeader
 * @returns
 */

export const setAuth = auth => localStorage.setItem(keyName, auth)

export const getAuth = () => localStorage.getItem(keyName)

export const removeAuth = () => localStorage.removeItem(keyName)

export const authTokenPresent = () => !!localStorage.getItem(keyName)
