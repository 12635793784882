import { useEffect } from "react"
import { wrapper } from "reducers/store"
import { Toaster } from "react-hot-toast"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"
import { closeSignInModal, closeReRateModal } from "reducers/auth"
import { authTokenPresent } from "utils/auth"
import { getCurrentUser, completeAuthCheck } from "api/auth"
import { toastOptions } from "utils/toastOptions"
import soehne from "utils/fonts"
import SignInModal from "components/signin"
import ReRateModal from "components/re-rate"
import Maintenance from "components/maintenance"
import CookieConsentModal from "components/cookieConsent"
import GoogleTagManagerScript from "utils/GoogleTagManagerScript"
import ReferralToast from "components/ReferralToast"
import { useSafariValidation } from "hooks/useValidateSafari"
import useQueryParamRemoval from "hooks/useQueryParamRemoval"
import useReferralToast from "hooks/useReferralToast"
import useAccessCheck from "hooks/useAccessCheck"

import "styles/main.scss"

function RepVue({ Component, pageProps }) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || (page => page)
  const router = useRouter()
  const dispatch = useDispatch()
  useSafariValidation()
  useQueryParamRemoval()
  useAccessCheck()
  const showReferralToast = useReferralToast()

  const { showSignInModal, currentUser, showReRateModal } = useSelector(
    state => state.auth
  )

  const { current_company } = currentUser

  const handleSignInClose = () => {
    dispatch(closeSignInModal())
  }

  const handleReRateClose = () => {
    dispatch(closeReRateModal())
  }

  const maintenanceMode = process.env.NEXT_PUBLIC_MODE === "maintenance"

  useEffect(() => {
    const isOnboardingRoute = router.pathname.startsWith("/onboarding")

    authTokenPresent()
      ? dispatch(getCurrentUser(isOnboardingRoute))
      : dispatch(completeAuthCheck())
  }, [dispatch])

  return (
    <>
      <style jsx global>{`
        :root {
          /* ... */
          --soehne-font: ${soehne.style.fontFamily};
        }
      `}</style>
      <GoogleTagManagerScript />
      {maintenanceMode ? (
        <Maintenance />
      ) : (
        getLayout(<Component {...pageProps} />, pageProps)
      )}
      <SignInModal show={showSignInModal} handleClose={handleSignInClose} />
      <ReRateModal
        show={showReRateModal}
        handleClose={handleReRateClose}
        current_company={current_company}
      />
      <Toaster position="bottom-center" toastOptions={toastOptions} />
      <CookieConsentModal />
      {showReferralToast && <ReferralToast />}
    </>
  )
}
export default wrapper.withRedux(RepVue)
