import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeReferralToastModal } from "reducers/auth"
import Button from "components/shared/Button"
import ReferralModal from "components/shared/Referrals/Modal"
import MoneyMessage from "icons/moneyMessage.svg"
import Close from "icons/close.svg"
import ChevronRight from "icons/chevron-right.svg"
import styles from "./referralToast.module.scss"

const ReferralToast = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector(state => state.auth)
  const { referral_link } = currentUser
  const [showReferralModal, setShowReferralModal] = useState(false)

  const handleClose = () => {
    dispatch(closeReferralToastModal())
  }

  return (
    <>
      <section className={styles.referralToast}>
        <article className={styles.referralToast__container}>
          <Close
            height="20"
            width="20"
            alt="close"
            className={styles.referralToast__close}
            onClick={handleClose}
          />
          <div className={styles.referralToast__cardLogo}>
            <MoneyMessage height="50" width="100" alt="close" />
          </div>
          <h6>Refer a friend to earn $10!</h6>
          <p className={styles.referralToast__description}>
            Know someone who would love RepVue? Send them your referral link &
            earn $10 when they sign up.
          </p>

          <Button
            className={styles.referralToast__action}
            variant="primary"
            onClick={() => setShowReferralModal(true)}
          >
            Refer a Friend <ChevronRight height="12" width="16" />
          </Button>
        </article>
      </section>
      <ReferralModal
        show={showReferralModal}
        handleClose={() => setShowReferralModal(false)}
        referralLink={referral_link}
      />
    </>
  )
}

export default ReferralToast
