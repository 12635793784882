import { useCallback, useEffect } from "react"
import { handleUtmTracking, checkUtmValidity } from "utils/analytics"
import { useRouter } from "next/router"

function useQueryParamRemoval() {
  const router = useRouter()

  const removeQueryParam = useCallback(
    param => {
      const { pathname, query } = router
      const params = new URLSearchParams(query)
      params.delete(param)
      router.replace({ pathname, query: params.toString() }, undefined, {
        shallow: true,
      })
    },
    [router]
  )

  useEffect(() => {
    const { ref, utm_campaign, utm_source, utm_medium } = router.query

    if (ref) {
      localStorage.setItem("referralCode", ref)
      removeQueryParam("ref")
    }

    if (utm_campaign) {
      const queryObject = {
        utm_campaign,
        utm_source,
        utm_medium,
      }
      handleUtmTracking(queryObject)
    } else {
      checkUtmValidity()
    }
  }, [router.query, removeQueryParam])

  return { removeQueryParam }
}

export default useQueryParamRemoval
