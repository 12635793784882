import { bool, string } from "prop-types"
import cn from "classnames"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import Info from "icons/info-fill.svg"
import styles from "./labelInput.module.scss"

const LabelInput = ({ label, isRequired, className, tooltip }) => (
  <article className={styles.labelInput__container}>
    <Form.Label className={cn(styles.labelInput, className)}>
      {label} {isRequired && <span className={styles.required}>*</span>}
    </Form.Label>
    {tooltip && (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-top">
            <span>{tooltip}</span>
          </Tooltip>
        }
      >
        <div className={styles.labelInput__icon}>
          <Info height={14} width={14} />
        </div>
      </OverlayTrigger>
    )}
  </article>
)

LabelInput.propTypes = {
  label: string,
  isRequired: bool,
  className: string,
  tooltip: string,
}

export default LabelInput
