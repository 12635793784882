import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import duration from "dayjs/plugin/duration"
import { salariesByCity, salariesByCountry } from "constants/salaries"

dayjs.extend(duration)
dayjs.extend(relativeTime)

/**
 * cleanTrim
 * @param {String} string
 * @param {Number} maxLength
 * @param {Boolean} [rough]
 * @returns
 */
export function cleanTrim(string, maxLength = 145, rough = false) {
  if (string) {
    const roughTrim = string.substr(0, maxLength)

    if (rough) {
      return string.length < maxLength
        ? string
        : roughTrim.substr(0, maxLength) + "..."
    }

    const trimmedDescription = roughTrim.substr(
      0,
      Math.min(roughTrim.length, roughTrim.lastIndexOf(" "))
    )
    return string.length < maxLength ? string : trimmedDescription + "..."
  }
  return ""
}

/**
 *  getLocationText
 * @param {Array} metro_locations
 * @param {String} location_type
 * @param {Boolean} [mobile]
 * @returns
 */
export function getLocationText(metro_locations, location_type) {
  if (metro_locations?.length === 1) {
    return metro_locations[0].name
  } else if (metro_locations?.length === 0) {
    return location_type
  } else {
    return "Multiple Locations"
  }
}

/**
 *  getOrdinal
 * @param {Number} n numerical value
 * @returns
 */
export function getOrdinal(n) {
  let ord = "th"

  if (n % 10 == 1 && n % 100 != 11) {
    ord = "st"
  } else if (n % 10 == 2 && n % 100 != 12) {
    ord = "nd"
  } else if (n % 10 == 3 && n % 100 != 13) {
    ord = "rd"
  }

  return ord
}

/**
 *  getTimeElapsed
 * @param {String} timestamp text string with timestamp
 * @returns
 */
export function getTimeElapsed(timestamp) {
  const now = dayjs()
  const elapsed = now.diff(dayjs(timestamp))

  return dayjs.duration(elapsed).humanize()
}

/**
 * getCookie
 * @param {String} name
 * @returns
 */
export function getCookie(name) {
  const cookies = document.cookie.split(";")
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1, cookie.length)
    }
  }
  return null
}

/**
 * getLocationString
 * @param {String} slug
 * @returns
 */
export function getLocationString(slug = "") {
  let matchedSalary = salariesByCity.filter(
    salary => salary.location_parameterized === slug
  )

  if (matchedSalary.length === 0) {
    matchedSalary = salariesByCountry.filter(
      salary => salary.location_parameterized === slug
    )
  }
  return matchedSalary.length > 0 ? matchedSalary[0].location : ""
}

/**
 * formatBytes - this function formats file sizes
 * @param {Number} bytes
 * @returns
 */
export function formatBytes(bytes) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  let size = bytes
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024
    unitIndex++
  }

  // Round the size to two decimal places
  size = Math.round(size * 100) / 100

  return `${size} ${units[unitIndex]}`
}

export function escapeRegex(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

/**
 * toSentenceCase - this function formats strings into sentence case
 * @param {String} inputString
 * @returns
 */
export function toSentenceCase(inputString) {
  if (typeof inputString !== "string" || inputString.length === 0) {
    return undefined
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1)
}

/**
 * underscore - this function formats strings into valid HTML selectors
 * @param {String} str
 * @returns
 */
export function underscore(str) {
  // Replace invalid characters with underscores
  let underscoredString = str.replace(/[^\w-]/g, "_")

  // If the string starts with a digit, prepend with an underscore
  if (/^\d/.test(underscoredString)) {
    underscoredString = "_" + underscoredString
  }

  return underscoredString
}

/**
 * kebabToTitleCase - this function formats strings in kabob case to title case
 * @param {String} str
 * @returns
 */
export function kebabToTitleCase(str) {
  let words = str.split("-")

  let titleCaseWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  let titleCaseStr = titleCaseWords.join(" ")

  return titleCaseStr
}

export const getArticle = word => {
  let lowerCaseWord = word?.toLowerCase()
  let firstLetter = lowerCaseWord?.charAt(0)
  let vowels = ["a", "e", "i", "o", "u"]
  return vowels.includes(firstLetter) ? "an" : "a"
}

export const getLabel = (value, options) => {
  const option = options.find(({ value: optionValue }) => optionValue === value)
  return option?.label
}
