import { useEffect, useState } from "react"
import { useRouter } from "next/navigation"
import { isMobileSafari } from "utils/validations"

export const useSafariValidation = () => {
  const router = useRouter()
  const [validateSafari, setValidateSafari] = useState(false)

  useEffect(() => {
    setValidateSafari(isMobileSafari())
  }, [])

  useEffect(() => {
    if (validateSafari) {
      const timeoutId = setTimeout(() => {
        window.scrollTo(0, -30)
      }, 25)

      return () => clearTimeout(timeoutId)
    }
  }, [router.asPath, validateSafari])

  return validateSafari
}
