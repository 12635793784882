import React from "react"
import classNames from "classnames"

import Button from "react-bootstrap/Button"

import styles from "./Button.module.scss"

const PrimaryButton = React.forwardRef((props, ref) => {
  const {
    children,
    onClick,
    disabled,
    size = "medium",
    type,
    className,
    variant,
    ariaLabel,
    dataCy,
  } = props
  return (
    <Button
      className={classNames({
        [styles.button]: true,
        [styles.small]: size === "small",
        [styles.medium]: size === "medium",
        [styles.large]: size === "large",
        [styles.extraLarge]: size === "xl",
        [styles[`${variant}`]]: variant,
        [`${className}`]: className,
      })}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      ref={ref}
      type={type}
      aria-label={ariaLabel}
      data-cy={dataCy}
    >
      {children}
    </Button>
  )
})

PrimaryButton.displayName = "PrimaryButton"

export default PrimaryButton
