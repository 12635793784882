import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setLockedOut } from "reducers/auth"
import { toggleScroll } from "utils/common"
import { Modal, Form } from "react-bootstrap"
import { resetPassword, signIn } from "api/auth"
import Router from "next/router"

import Button from "components/shared/Button"
import PasswordField from "../shared/Forms/PasswordField"
import Link from "next/link"
import Error from "components/shared/Notifications/Error"

import Email from "icons/email.svg"
import EmailWithLock from "icons/email-with-lock.svg"
import Close from "icons/close.svg"
import BackArrow from "icons/back-arrow.svg"
import Login from "icons/login.svg"

import styles from "./signin.module.scss"

const SignInModal = ({ show, handleClose, redirect }) => {
  const dispatch = useDispatch()
  const { showForgotPassword } = useSelector(state => state.auth)

  const [form, setForm] = useState({
    email: "",
    password: "",
  })
  const [error, setError] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [resetConfirmation, setResetConfirmation] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setError(false)

    const { email, password } = form
    const params = { user: { email, password } }

    dispatch(signIn(params))
      .then(() => {
        dispatch(setLockedOut(false))
        toggleScroll(true)
        handleClose()
        if (redirect) {
          Router.push(redirect)
        }
      })
      .catch(error => {
        console.log(error)
        setError(true)
      })
  }

  const handleChange = event => {
    const { value, name } = event.target
    setForm({ ...form, [name]: value })
  }

  const handleForgotPassword = e => {
    e.preventDefault()
    setError(false)

    const { email } = form
    const params = { user: { email } }
    dispatch(resetPassword(params))
      .then(() => {
        setResetConfirmation(true)
        setForgotPassword(false)
      })
      .catch(() => {
        setError(true)
      })
  }

  const modalClose = () => {
    handleClose()
    setForgotPassword(false)
    setResetConfirmation(false)
  }

  useEffect(() => {
    setForgotPassword(showForgotPassword)
  }, [showForgotPassword])

  return (
    <Modal show={show} onHide={modalClose} className={styles.modal}>
      <Modal.Body>
        {!forgotPassword && !resetConfirmation ? (
          <>
            <div className={styles.header}>
              <h5 className={styles.title}>Sign Into Your Account</h5>
              <Close
                height="20"
                width="20"
                onClick={handleClose}
                className={styles.closeButton}
              />
            </div>
            {error && <Error>Incorrect email or password.</Error>}
            <Form onSubmit={handleSubmit}>
              <div className={styles.inputWrapper}>
                <Email height="20" width="20" className={styles.iconLeft} />
                <Form.Control
                  className={styles.input}
                  type="email"
                  placeholder="Email Address"
                  value={form.email}
                  name="email"
                  onChange={handleChange}
                  id="email-sign-in"
                  autoFocus
                />
              </div>
              <PasswordField
                value={form.password}
                handleChange={handleChange}
              />
              <div
                className={styles.link}
                onClick={() => {
                  setForgotPassword(true)
                }}
              >
                Forgot password?
              </div>
              <div id="sign-in-button">
                <Button
                  className={styles.button}
                  type="submit"
                  variant="primary"
                >
                  Sign In
                  <Login height="26" width="26" />
                </Button>
              </div>
            </Form>
            <div className={styles.footer}>
              {`Don't have an account yet? Take 3 minutes to join RepVue by rating a company.`}
              <Link href="/rating/step1" onClick={modalClose}>
                Rate a company
              </Link>
              .
            </div>
          </>
        ) : !resetConfirmation ? (
          <>
            <div className={styles.header}>
              <div
                className={styles.return}
                onClick={() => {
                  setForgotPassword(false)
                }}
              >
                <BackArrow height={18} width={18} />
                <span>Cancel & Return</span>
              </div>
              <Close
                height={20}
                width={20}
                onClick={handleClose}
                className={styles.closeButton}
              />
            </div>
            <Form onSubmit={handleForgotPassword}>
              <div className={styles.forgot}>Forgot your password?</div>
              <div className={styles.noProblem}>
                No problem! Enter your email address below and we&apos;ll send
                you a link to reset your password.
              </div>
              <div className={styles.inputWrapper}>
                <Email height="20" width="20" className={styles.iconLeft} />
                <Form.Control
                  className={styles.input}
                  type="email"
                  placeholder="Your email address"
                  value={form.email}
                  name="email"
                  onChange={handleChange}
                  autoFocus
                />
              </div>
              {error && <Error>Please enter a valid email address.</Error>}
              <Button
                size="large"
                type="submit"
                variant="primary"
                className={styles.button}
              >
                Send Password Reset Link
              </Button>
            </Form>
          </>
        ) : (
          <div className={styles.confirmation}>
            <div className={styles.confirmation__header}>
              <Close
                height={20}
                width={20}
                onClick={handleClose}
                className={styles.closeButton}
              />
            </div>
            <EmailWithLock
              height={100}
              width={100}
              className={styles.confirmation__lock}
            />
            <div className={styles.confirmation__check}> Check your inbox!</div>
            <div className={styles.confirmation__body}>
              In the next few minutes, a password reset link will show up in the
              inbox of <strong>{form.email}</strong>
            </div>
            <div className={styles.confirmation__body__receive}>
              Didn&apos;t receive the email?{" "}
              <div
                className={styles.confirmation__body__receive__reenter}
                onClick={() => {
                  setForgotPassword(true)
                  setResetConfirmation(false)
                }}
              >
                Re-enter your email address
              </div>
            </div>
            <Button
              size="large"
              className={styles.confirmation__button}
              onClick={() => {
                setForgotPassword(false)
                setResetConfirmation(false)
              }}
              variant="outline-dark"
            >
              Return to Login
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default SignInModal
