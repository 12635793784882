import { useState } from "react"
import { sendReferrals } from "api/referrals"
import { trackAction } from "utils/analytics"
import { toast } from "react-hot-toast"
import pluralize from "pluralize"
import { cleanTrim } from "utils/string"
import classNames from "classnames"

import Error from "next/error"
import SuccessToast from "../Notifications/SuccessToast"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import InputComponent from "components/shared/InputComponent"
import Button from "components/shared/Button"

import Close from "icons/close.svg"
import Copy from "icons/copy.svg"

import styles from "./Form.module.scss"

const ReferralForm = ({ referralLink, modal, handleClose }) => {
  const initialFormState = {
    firstEmail: "",
    secondEmail: "",
    thirdEmail: "",
  }
  const [referralEmails, setReferralEmails] = useState(initialFormState)
  const [errors, setErrors] = useState([])

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`${referralLink}`)
      setCopied(true)
    } catch (error) {
      console.error("Failed to copy: ", error)
    }
  }

  const onChange = (field, value) => {
    setReferralEmails({ ...referralEmails, [field]: value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const referralArray = Object.keys(referralEmails)
      .filter(email => referralEmails[email].length > 0)
      .map(email => referralEmails[email])
    const payload = {
      data: {
        emails: referralArray,
      },
    }
    sendReferrals(payload)
      .then(() => {
        trackAction("Invite Sent", {
          invitee_emails: referralArray,
        })
        if (modal) handleClose()
        setReferralEmails(initialFormState)
        toast.custom(t => (
          <SuccessToast
            t={t}
            headline={`Referral ${pluralize(
              "emails",
              referralArray.length,
              false
            )} sent to ${
              referralArray.length === 1
                ? referralArray[0]
                : referralArray.length === 2
                ? `${referralArray[0]} and ${referralArray[1]}`
                : `${referralArray[0]}, ${referralArray[1]}, and ${referralArray[2]}`
            }`}
          />
        ))
      })
      .catch(error => {
        console.error(error)
        setErrors(error)
      })
  }

  const readyCheck =
    referralEmails.firstEmail.length > 0 ||
    referralEmails.secondEmail.length > 0 ||
    referralEmails.thirdEmail.length > 0

  return (
    <Form
      className={classNames(styles.form, { [styles.form__dashboard]: !modal })}
      onSubmit={event => handleSubmit(event)}
    >
      {modal && (
        <>
          <div className={styles.header}>
            <h6 className={styles.headline}>Refer your friends</h6>
            <Close
              height="20"
              width="20"
              onClick={() => handleClose()}
              className={styles.closeButton}
            />
          </div>
          <p>
            Enter emails or share a link with your sales colleagues and earn $10
            for each verified rating.
          </p>
        </>
      )}
      <span className={styles.title}>Your Referral Link</span>
      <div className={styles.referral}>
        <span className={styles.link__lg}>{referralLink}</span>
        <span className={styles.link__mobile}>
          {cleanTrim(referralLink, 28, true)}
        </span>
        <OverlayTrigger
          placement="top"
          trigger="click"
          rootClose
          overlay={<Tooltip>Referral link copied!</Tooltip>}
          className={styles.tooltip}
        >
          <div className={styles.copy} onClick={handleCopy}>
            <Copy height="20" width="20" />
            Copy Link
          </div>
        </OverlayTrigger>
      </div>
      {errors?.length > 0 && <Error>Something went wrong.</Error>}
      <span className={styles.title}>Email Your Referral Link</span>
      <Form.Group>
        <InputComponent
          className={styles.input}
          type="email"
          placeholder="First email address"
          value={referralEmails.firstEmail}
          onChange={e => {
            onChange("firstEmail", e.target.value)
          }}
          name="firstEmail"
          error={errors?.thirdEmail}
          errorMessage={errors?.thirdEmail?.[0]}
        />
        <InputComponent
          className={styles.input}
          type="email"
          placeholder="Second email address"
          value={referralEmails.secondEmail}
          onChange={e => {
            onChange("secondEmail", e.target.value)
          }}
          name="secondEmail"
          error={errors?.secondEmail}
          errorMessage={errors?.secondEmail?.[0]}
        />
        <InputComponent
          className={styles.input}
          type="email"
          placeholder="Third email address"
          value={referralEmails.thirdEmail}
          onChange={e => {
            onChange("thirdEmail", e.target.value)
          }}
          name="thirdEmail"
          error={errors?.thirdEmail}
          errorMessage={errors?.thirdEmail?.[0]}
        />
      </Form.Group>
      <div className={styles.footer}>
        {modal && (
          <Button
            size="large"
            onClick={() => handleClose()}
            variant="outline-dark"
          >
            Cancel
          </Button>
        )}
        <Button
          size="large"
          disabled={!readyCheck}
          type="submit"
          variant="primary"
        >
          Send Referral Email
        </Button>
      </div>
    </Form>
  )
}

export default ReferralForm
