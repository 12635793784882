import classNames from "classnames"

import Instagram from "icons/instagram.svg"
import Facebook from "icons/facebook-gray.svg"
import Twitter from "icons/twitter-gray.svg"
import TikTok from "icons/tik-tok.svg"
import LinkedIn from "icons/linkedin-gray.svg"

import styles from "./socials.module.scss"

const Socials = () => (
  <ul className={styles.topList}>
    <li>
      <a
        className={classNames(styles.links, styles.linkedin)}
        href="https://www.linkedin.com/company/repvue"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <LinkedIn height={18.67} width={18.67} />
      </a>
    </li>
    <li>
      <a
        className={classNames(styles.links, styles.insta)}
        href="https://www.instagram.com/repvue"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      >
        <Instagram height={25} width={25} />
      </a>
    </li>
    <li>
      <a
        className={classNames(styles.links, styles.tikTok)}
        href="https://www.tiktok.com/@repvue"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="TikTok"
      >
        <TikTok height={20} width={20} />
      </a>
    </li>
    <li>
      <a
        className={classNames(styles.links, styles.twitter)}
        href="https://twitter.com/repvue?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Twitter"
      >
        <Twitter height={20} width={16} />
      </a>
    </li>
    <li>
      <a
        className={classNames(styles.links, styles.facebook)}
        href="https://www.facebook.com/repvue"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
      >
        <Facebook height={18.67} width={18.67} />
      </a>
    </li>
  </ul>
)

export default Socials
