/**
 * imgixLoader
 * @param {String} src url image
 * @param {String|Number} width
 * @param {String|Number} quality
 * @returns
 */

export default function imgixLoader({ src, width, quality = 50, alt }) {
  if (src[0] === "/") {
    return src
  }
  const url = new URL(`${process.env.NEXT_PUBLIC_IMAGE_URL}/${src}`)
  const params = url.searchParams
  params.set("auto", params.getAll("auto").join(",") || "format")
  params.set("fit", params.get("fit") || "max")
  params.set("w", params.get("w") || width.toString())
  params.set("q", quality.toString() || "50")
  return url.href
}
