import Link from "next/link"
import LocalImage from "../shared/LocalImage"

import logo from "assets/repvue-white.png"
import styles from "./Logo.module.scss"

const Logo = ({ handleClick, allowNavigation = true }) =>
  allowNavigation ? (
    <Link href="/">
      <div className={styles.logo} onClick={handleClick}>
        <LocalImage alt="RepVue" src={logo} />
      </div>
    </Link>
  ) : (
    <div className={styles.logo} onClick={handleClick}>
      <LocalImage alt="RepVue" src={logo} />
    </div>
  )

export default Logo
