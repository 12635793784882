import Image from "next/image"
import styles from "./LocalImage.module.scss"

const LocalImage = ({ src, alt, priority, width, height }) => (
  <div className={styles.wrapper}>
    {width && height ? (
      <Image
        loader={src}
        src={src}
        alt={alt}
        unoptimized
        priority={priority}
        width={width}
        height={height}
      />
    ) : (
      <Image loader={src} src={src} alt={alt} unoptimized priority={priority} />
    )}
  </div>
)

export default LocalImage
