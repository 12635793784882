import { useEffect, useMemo, useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { debounce } from "utils/common"

const useReferralToast = () => {
  const WAIT_TIME = 300000
  const [showReferralToast, setReferralToast] = useState(false)
  const { authenticated, showReferralToastModal } = useSelector(
    state => state.auth
  )

  const handleRefererFriend = useCallback(() => {
    setReferralToast(true)
  }, [])

  const debounceHandleRefererFriend = useMemo(
    () => debounce(handleRefererFriend, WAIT_TIME),
    [handleRefererFriend]
  )

  useEffect(() => {
    if (!!!showReferralToastModal || !!!authenticated) setReferralToast(false)
    if (showReferralToastModal && authenticated) debounceHandleRefererFriend()
  }, [authenticated, showReferralToastModal, debounceHandleRefererFriend])

  return showReferralToast
}

export default useReferralToast
