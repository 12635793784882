import { createSlice } from "@reduxjs/toolkit"
import { removeAuth } from "utils/auth"
import { getCurrentUser } from "api/auth"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authenticated: false,
    showSignInModal: false,
    showForgotPassword: false,
    showReRateModal: false,
    showNextStepsModal: false,
    showSearchModal: false,
    showCandidateProfileModal: false,
    loading: true,
    currentUser: {},
    showReferralToastModal: false,
    lockedOut: false,
  },
  reducers: {
    signOutUser: {
      reducer(state, _) {
        removeAuth()
        state.authenticated = false
        state.currentUser = {}
      },
    },
    setCurrentUser: {
      reducer(state, action) {
        state.currentUser = action.payload
        state.authenticated = true
        state.loading = false
      },
    },
    setCurrentUserFromProfile: {
      reducer(state, action) {
        const newUser = {
          ...action.payload,
          watched_companies: state.currentUser?.watched_companies || [],
        }
        state.currentUser = newUser
        state.authenticated = true
        state.loading = false
      },
    },
    setLoading: {
      reducer(state, _) {
        state.checking = false
        state.loading = false
      },
    },
    setLockedOut: {
      reducer(state, action) {
        state.lockedOut = action.payload
      },
    },
    openSignInModal: {
      reducer(state, _) {
        state.showSignInModal = true
        state.showForgotPassword = false
      },
    },
    closeSignInModal: {
      reducer(state, _) {
        state.showSignInModal = false
      },
    },
    openForgotPassword: {
      reducer(state, _) {
        state.showForgotPassword = true
      },
    },
    openReRateModal: {
      reducer(state, _) {
        state.showReRateModal = true
      },
    },
    closeReRateModal: {
      reducer(state, _) {
        state.showReRateModal = false
      },
    },
    openNextStepsModal: {
      reducer(state, _) {
        state.showNextStepsModal = true
      },
    },
    closeNextStepsModal: {
      reducer(state, _) {
        state.showNextStepsModal = false
      },
    },
    addToWatchList: {
      reducer(state, action) {
        state.currentUser?.watched_companies.push(action.payload)
      },
    },
    toggleCandidateProfileModal: {
      reducer(state, action) {
        state.showCandidateProfileModal = action.payload
      },
    },
    removeFromWatchList: {
      reducer(state, action) {
        state.currentUser.watched_companies =
          state.currentUser?.watched_companies.filter(
            company => company.name !== action.payload
          )
      },
    },
    addAppliedJob: {
      reducer(state, action) {
        const newJob = {
          id: action?.payload?.job?.id || action?.payload?.id,
          slug: action?.payload?.job?.slug || action?.payload?.slug,
        }
        state.currentUser.active_applied_jobs.push(newJob)
      },
    },
    updateUserApplicationInfo: {
      reducer(state, action) {
        state.currentUser.phone = action.payload.phone
        state.currentUser.phone_country_code = action.payload.phone_country_code
        state.currentUser.first_name = action.payload.first_name
        state.currentUser.last_name = action.payload.last_name
        state.currentUser.email = action.payload.email
        state.currentUser.linkedin_url = action.payload.linkedin_url
      },
    },
    openReferralToastModal: {
      reducer(state, _) {
        state.showReferralToastModal = true
      },
    },
    closeReferralToastModal: {
      reducer(state, _) {
        state.showReferralToastModal = false
      },
    },
    resetDaysUntilNextRating: {
      reducer(state, _) {
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            days_until_next_rating: 119,
          },
        }
      },
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload
        state.authenticated = true
        state.loading = false
      })
      .addCase(getCurrentUser.rejected, state => {
        state.loading = false
      })
      .addCase(getCurrentUser.pending, state => {
        state.loading = true
      })
  },
})

export const {
  signOutUser,
  setCurrentUser,
  setLoading,
  openSignInModal,
  closeSignInModal,
  openForgotPassword,
  openReRateModal,
  closeReRateModal,
  openNextStepsModal,
  closeNextStepsModal,
  addToWatchList,
  removeFromWatchList,
  addAppliedJob,
  updateUserApplicationInfo,
  openReferralToastModal,
  closeReferralToastModal,
  resetDaysUntilNextRating,
  toggleCandidateProfileModal,
  setLockedOut,
  setCurrentUserFromProfile,
} = authSlice.actions

export default authSlice.reducer
