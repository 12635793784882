import mixpanel from "mixpanel-browser"
import { UAParser } from "ua-parser-js"
import dayjs from "dayjs"

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)

export const handleUtmTracking = params => {
  localStorage.utm_campaign = params.utm_campaign
  localStorage.utm_medium = params.utm_medium
  localStorage.utm_source = params.utm_source
  localStorage.utm_date = dayjs()
}

export const checkUtmValidity = () => {
  if (
    localStorage.utm_date &&
    dayjs().isAfter(dayjs(localStorage.utm_date).add(30, "day"))
  ) {
    localStorage.removeItem("utm_campaign")
    localStorage.removeItem("utm_source")
    localStorage.removeItem("utm_medium")
    localStorage.removeItem("utm_date")
  }
}

export const trackAction = (action, data) => {
  mixpanel.track(action, data)
}

export const pageCall = (category, name, data) => {
  const additionalFields = data ? { ...data } : {}
  const agentData = new UAParser(navigator.userAgent)
  const { pathname, search } = window.location

  additionalFields.device = agentData.getDevice()
  additionalFields.name = name
  additionalFields.path = pathname
  additionalFields.search = search
  additionalFields.category = category

  mixpanel.track(`Viewed ${name}`, additionalFields)
}

export const identifyUser = (user, data) => {
  mixpanel.identify(user?.uuid || user)
  if (data) {
    mixpanel.people.set(data)
  }
}

export const resetUser = () => {
  mixpanel.reset()
}

export const checkLockoutTest = setLockoutFlag => {
  const userGroup = "Ctrl_Gated"
  if (localStorage.experiment_group_lockout) {
    if (localStorage.experiment_group_lockout === "Test_Metered") {
      localStorage.setItem("experiment_group_lockout", userGroup)
      setLockoutFlag(userGroup)
    }

    const valuesToRemove = ["lockout_timer", "lockout"]

    valuesToRemove.forEach(value => {
      if (localStorage[value]) {
        localStorage.removeItem(value)
      }
    })

    setLockoutFlag(localStorage.experiment_group_lockout)
  } else {
    // const userGroup = Math.random() < 0.5 ? "Ctrl_Gated" : "Test_Metered"
    localStorage.setItem("experiment_group_lockout", userGroup)
    setLockoutFlag(userGroup)
    setTimeout(() => {
      trackAction("Experiment Assigned", {
        experiment_group_lockout: userGroup,
      })
    }, 500)
  }
}
