import React from "react"
import { bool, string, func, oneOfType, array, number } from "prop-types"
import cn from "classnames"
import { toSentenceCase } from "utils/string"

import Form from "react-bootstrap/Form"
import LabelInput from "components/shared/LabelInput"
import styles from "./inputComponent.module.scss"

const InputComponent = ({
  type = "text",
  placeholder,
  onChange,
  className,
  size,
  name,
  value,
  label,
  isRequired,
  error,
  errorMessage,
  readOnly,
  inputmode = "text",
}) => {
  return (
    <div className={cn(className)}>
      {label && <LabelInput label={label} isRequired={isRequired} />}
      <Form.Control
        className={cn(styles.inputComponent, {
          "form-control is-invalid": error,
        })}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        size={size}
        name={name}
        value={value || ""}
        readOnly={readOnly}
        inputMode={inputmode}
      />
      {error && (
        <span className={styles.inputComponent__errorMessage}>
          {errorMessage
            ? toSentenceCase(errorMessage)
            : `${label || name} must be valid.`}
        </span>
      )}
    </div>
  )
}

InputComponent.propTypes = {
  type: string,
  placeholder: string,
  onChange: func,
  className: string,
  size: string,
  name: string,
  value: oneOfType([string, number]),
  isRequired: bool,
  label: string,
  error: oneOfType([bool, array]),
  errorMessage: oneOfType([string, array]),
}

export default InputComponent
