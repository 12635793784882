import classNames from "classnames"

import styles from "./Error.module.scss"

const Error = ({ children, className }) => (
  <div
    className={classNames({
      [`${styles.error}`]: true,
      [className]: className,
    })}
  >
    {children}
  </div>
)

export default Error
