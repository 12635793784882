import localFont from "next/font/local"

const soehne = localFont({
  src: [
    {
      path: "../styles/fonts/soehne-buch.woff2",
      weight: "400",
    },
    {
      path: "../styles/fonts/soehne-kraftig.woff2",
      weight: "500",
    },
    {
      path: "../styles/fonts/soehne-halbfett.woff2",
      weight: "700",
    },
  ],
})

export default soehne
