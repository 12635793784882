import { toast } from "react-hot-toast"

import CheckCircle from "icons/check-circle.svg"
import Close from "icons/close.svg"

const SuccessToast = ({ t, headline, body }) => {
  return (
    <div className="toast-success">
      <div className="toast-success-wrapper">
        <div className="toast-success-header">
          <Close
            height="20"
            width="20"
            onClick={() => {
              toast.remove(t.id)
            }}
            className="toast-success-close"
          />
        </div>
        <div className="toast-success-body">
          <CheckCircle height={20} width={20} />
          <div className="toast-success-children">
            <div className="toast-success-text toast-success-headline">
              {headline}
            </div>
            <div className="toast-success-text">{body}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessToast
